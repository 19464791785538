import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
import { CodeBlock } from '../../../src/components/DigitalGarden';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "React"`}</p>



    <h2 {...{
      "id": "react",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#react",
        "aria-label": "react permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`React`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#display-app-on-a-full-screen"
        }}>{`Display app on a full screen`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#add-multiple-components"
        }}>{`Add multiple components`}</a></li>
    </ol>
    <h3 {...{
      "id": "display-app-on-a-full-screen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#display-app-on-a-full-screen",
        "aria-label": "display app on a full screen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Display app on a full screen`}</h3>
    <p>{`Add to index.css`}</p>
    <CodeBlock mdxType="CodeBlock">{`html, body, #root, #root>div {
  height: 100%;
}`}</CodeBlock>
    <hr></hr>
    <h3 {...{
      "id": "add-multiple-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-multiple-components",
        "aria-label": "add multiple components permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add multiple components`}</h3>
    <CodeBlock mdxType="CodeBlock">{`
  const n = 5; //number of repeats
[...Array(n)].map((e, i) => <NameOfComponent key={i} />)
`}</CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      